.footer {
  @apply fixed bg-white w-full left-0 bottom-0 py-4 border-t-[1px] z-[1] border-others-border;

  @media screen and (min-width: 1800px) {
    @apply py-5;
  }
}

.footer_separates {
  @apply flex items-center justify-between;
}

.copytights {
  @apply text-body1 2xl:text-base font-normal text-font-textcolor1 tracking-wide;
}

.footer_icon_separates {
  @apply flex justify-evenly gap-[27px];
}

.configuration_footer {
  @apply relative bg-white w-full left-0 bottom-0 py-4 border-t-[1px] border-others-border;

  @media screen and (min-width: 1800px) {
    @apply py-5;
  }
}
