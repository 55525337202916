.header {
  @apply flex flex-row fixed bg-transparent top-0 left-0 right-0 w-full z-[100];
}

.header_logo {
  @apply flex justify-start items-center w-[120px] 2xl:w-[184px];
}

.menu_separates {
  @apply flex flex-row items-center justify-end gap-7;
}

.menu_link {
  @apply bg-transparent cursor-pointer text-base 2xl:text-subtitle2 font-medium leading-6;
}

.header_separates {
  @apply grid grid-cols-2 pt-[20px] 2xl:pt-[25px] w-full items-center;
}
