.Input {
  label {
    @apply text-primary-main;
    transition: all 0.3s ease;
  }
  input,
  textarea {
    @apply leading-5 text-primary-main border-2 outline-none;
    transition: all 0.3s ease;

    @media screen and (max-width: 320px) {
      // @apply text-body2;
    }
  }
  small {
    @apply text-sm leading-3 mt-1;
  }
  // &.hover {
  //   input,
  //   textarea {
  //     // @apply border-input-hover;
  //   }
  // }
  &.active {
    label {
      @apply text-primary-main;
    }
    input,
    textarea {
      @apply border-primary-main caret-primary-main;
    }
  }
  &.error {
    label {
      @apply text-error-main;
    }
    input,
    textarea {
      @apply caret-error-main border-error-main;
    }
    small {
      @apply text-error-main;
    }
  }
}

.primary {
  @apply block w-full text-body1 xl:text-smalltext border-others-iconcolorlight focus:border-primary-main rounded-md appearance-none focus:outline-none bg-transparent focus:ring-0;
}

.bgwhite {
  @apply bg-white;
}

.floatingLabel {
  @apply absolute bg-white left-3 md:top-[10px] text-font-textcolor3 duration-300 text-body1;
  transform-origin: 0%;
  letter-spacing: 0.02em;
}

.labelprimary {
  @apply bg-others-white;
}

.labelsecondary {
  @apply bg-others-background;
}

.inputright_space {
  input {
    @apply pr-10;
  }
}

.inputleft_space {
  input {
    @apply pl-10;
  }
}
